import {Data, VkUser} from "./data";
import { QuizType, VkData } from "./quiz";

export type Order = 'asc' | 'desc';

export interface HeadCell {
    id: keyof Data;
    label: string;
}

export interface Tableprops {
    data: Data[],
    nameOfTable?: string,
    profile?: QuizType,
    modInfo?: Data[],
    exId?: number,
}

export interface TablepropsVk {
    /*data: VkData,
    nameOfTable?: string,
    profile?: QuizType,
    modInfo?: VkUser[],
    exId?: number,
    onPageChange: (page: number) => void,*/

    data: VkData;
    nameOfTable?: string;
    profile?: QuizType;
    modInfo?: VkUser[];
    exId?: number;
    page: number;
    rowsPerPage: number;
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface EnhancedTableToolbarProps {
    numSelected: number,
    nameOfTable?: string,
    modInfo?: Data[],
}

export interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

export const headCells: HeadCell[] = [
    {id: 'defer',         label: 'Отл',                       },
    {id: 'defer',         label: 'Выборка',                   },
    {id: 'firstname',     label: 'Имя и фамилия',             },
    {id: 'birthday',      label: 'Дата рождения',             },
    {id: 'birthyear',     label: 'В год кого',                },
    {id: 'zodiak',        label: 'Знак',                      },
    {id: 'langlove',      label: 'Осн. язык',                 },
    {id: 'langlove2',     label: 'Втор. язык',                },
    {id: 'vip',           label: 'VIP',                       },
    {id: 'phone',         label: 'Телефон',                   },
    {id: 'vk',            label: 'VK',                        },
    {id: 'vk',            label: 'VK ссылка',                 },
    {id: 'id',            label: 'Ред.',                      },
    {id: 'id',            label: 'Контрольная дата контакта', },
    {id: 'id',            label: 'Сохранить',                 },
    {id: 'id',            label: 'Админу',                    },

    //{id: 'id',            label: 'Экспорт',                   },
    //{id: 'id',            label: 'На обработку',              },
    //{id: 'id',            label: 'Главному модератору',       },
    {id: 'id',            label: 'Мод1',                      },
    {id: 'id',            label: 'Мод2',                      },
    {id: 'id',            label: 'Мод3',                      },
    {id: 'id',            label: 'Мод4',                      },
    {id: 'id',            label: 'Мод5',                      },
    {id: 'id',            label: 'Мод6',                      },
    {id: 'id',            label: 'Мод7',                      },
    {id: 'id',            label: 'Мод8',                      },
    {id: 'id',            label: 'Мод9',                      },
    //{id: 'id',            label: 'На модерацию',              },
    //{id: 'id',            label: 'Архив',                     },
    {id: 'id',            label: 'Раздел',                    },
    //{id: 'id',            label: 'Информация',                },
    //{id: 'id',            label: 'В корзину',                 },
    //{id: 'id',            label: 'Удалить',                   },
    {id: 'last_modify',   label: 'Регистрация',               },
    {id: 'reg_date',      label: 'Последнее изменение',       },
    {id: 'source_type',   label: 'Источник',                  },
    {id: 'source',        label: 'Откуда узнали',             },
];

export const headCellsVK: HeadCell[] = [
    {id: 'defer_vk',      label: 'Отл',                       },
    {id: 'id',            label: 'ID',                        },
    {id: 'id',            label: 'PM',                        },
    {id: 'id',            label: 'Avatar',                    },
    {id: 'sex',           label: 'Пол',                       },
    {id: 'firstname',     label: 'Имя и фамилия',             },
    {id: 'birthday',      label: 'Дата рождения',             },
    {id: 'relation',      label: 'Статус',                    },
    {id: 'city',          label: 'Город',                     },
    {id: 'vk',            label: 'VK ссылка',                 },
    {id: 'vk',            label: 'Дата',                      },
    {id: 'id',            label: 'Удалить',                   },
];

export interface reorderProps {
    list: Data[],
    startIndex: number,
    endIndex: number
}