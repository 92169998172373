import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";

interface CheBoxProps extends FormInputProps {
    options: Options[],
    forceValidate?: boolean
}

type Options = { value: string; label?: string }
const FormInputDropdown : React.FC<CheBoxProps> = ({
    name,
    control,
    label,
    options,
    defaultValue,
    forceValidate
}) => {
    const generateSingleOptions = () => {
        return options.map((option) => {
            switch (option.value){
                case '-1':
                    return (
                        <MenuItem disabled={true}>
                            {option.label}
                        </MenuItem>
                    );
                    break;
                default:
                    return (
                        <MenuItem key={option.value} value={option.value}>
                            {(option.label ? option.label : option.value)}
                        </MenuItem>
                    );
                    break;
            }
        });
    };

    const validate = (value: string | undefined) => {
        //console.warn('forceValidate', forceValidate)
        //console.warn(typeof value, value?.length)
        if (forceValidate && value === '') {
            return 'Выберите хотя бы один вариант'; // Сообщение об ошибке
        }
        return undefined;
    };

    return (
        <FormControl style={{ width: "100%" }}>
            <InputLabel id={`${label}_label`}>{label}</InputLabel>
            <Controller
                defaultValue={defaultValue}
                rules={{ validate }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <React.Fragment>
                        <Select
                            labelId={`${label}_label`}
                            label={label}
                            onChange={onChange}
                            value={value}
                            //error={!!fieldState.error}
                        >
                            {generateSingleOptions()}
                        </Select>
                        {/*{fieldState.error ? (
                            <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                        ) : null}*/}
                        {error && (
                            <FormHelperText error style={{textAlign: 'center'}}>{error.message}</FormHelperText>
                        )}
                    </React.Fragment>
                )}
                control={control}
                name={name}
                //rules={{ required: `Пожалуйста, выберите "${label}"` }}
            />
        </FormControl>
    );
};

export default FormInputDropdown;
