import React from "react";
import {Typography} from "@mui/material";
import TextureIcon from "@mui/icons-material/Texture";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const printUserInfo = (fieldName: string, fieldData: string | number | null, link: boolean, except?: string) => {
    if (fieldData == 'hidden') {
        return (<Typography
            variant="subtitle1"
            component="p"
            sx={{ marginTop: 3, marginBottom: 3 }}
            className='hiddenField'>
                <b>{fieldName}:</b> <TextureIcon />
        </Typography>);
    } else {
        if (except && fieldData == except) {
            return null;
        }
    }

    switch (fieldName) {
        case 'sign_m':
            fieldName = 'Знак по западной астрологии';
            switch (fieldData){
                case '0': fieldData = 'Водолей'; break;
                case '1': fieldData = 'Рыбы'; break;
                case '2': fieldData = 'Овен'; break;
                case '3': fieldData = 'Телец'; break;
                case '4': fieldData = 'Близнецы'; break;
                case '5': fieldData = 'Рак'; break;
                case '6': fieldData = 'Лев'; break;
                case '7': fieldData = 'Дева'; break;
                case '8': fieldData = 'Весы'; break;
                case '9': fieldData = 'Скорпион'; break;
                case '10': fieldData = 'Стрелец'; break;
                case '11': fieldData = 'Козерог'; break;
            }
            break;
        case 'sign_y':
            fieldName = 'Знак по восточной астрологии';
            switch (fieldData){
                case '0': fieldData = 'Крыса'; break;
                case '1': fieldData = 'Бык'; break;
                case '2': fieldData = 'Тигр'; break;
                case '3': fieldData = 'Кролик'; break;
                case '4': fieldData = 'Дракон'; break;
                case '5': fieldData = 'Змея'; break;
                case '6': fieldData = 'Лошадь'; break;
                case '7': fieldData = 'Коза'; break;
                case '8': fieldData = 'Обезьяна'; break;
                case '9': fieldData = 'Петух'; break;
                case '10': fieldData = 'Собака'; break;
                case '11': fieldData = 'Кабан'; break;
            }
            break;
    }

    if (!link) {
        return (<Typography
            variant="subtitle1"
            component="p"
            sx={{ marginTop: 3, marginBottom: 3 }}>
                <b>{fieldName}:</b> {fieldData}
        </Typography>);
    } else {
        if (fieldData) {
            let mylink = fieldData.toString();
            return (<Typography
                variant="subtitle1"
                component="p"
                sx={{ marginTop: 3, marginButtom: 3 }}>
                    <b>{fieldName}:</b> <a href={mylink} target='_blank' rel={'nofollow noreferrer'}>Перейти&nbsp;<OpenInNewIcon sx={{ width: '15px', height:'15px'}} /></a></
                Typography>);
        }
    }
    return null;
}
