import { FC, useEffect, useState } from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useGetApiVkQuery, useSubmitDataMutation, useSubmitDataVkMutation} from "../../services/goroskop";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid, Modal } from '@mui/material';
import PrintVkUser from "./vkuser";
import {Data, VkUser} from "../../types/data";
import { Box } from "@mui/system";
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useAppSelector } from "../../hooks/hooks";
import useWhoIs from "../../hooks/useWhoIs";
import {deleteCookie, getCookie, setCookie} from "../../functions/Cookie";
import '../../profiles.css'
import Alert from '@mui/material/Alert';
import regionCodes from "../forAll/regions";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import {printCompatible, printRegionLabel, printLock, printLeftBottom} from "./constituents/cabinetInfo"
import {PrintFooterAstrodating} from './constituents/printFooter'
import {VkData} from "../../types/quiz";
import {getImagesSrcVk} from "./constituents/cabinetInfo";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: '-1px',
            left: '-1px',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const pluralizeRus = (n: number, forms: string[]) => {
    return n % 10 == 1 && n % 100 != 11
        ? forms[0]
        : (n % 10 >= 2 && n % 10 <= 4
        && (n % 100 < 10
            || n % 100 >= 20) ? forms[1] : forms[2]);
}

const CabinetVK: React.FC = () => {

    const updateCookieTime = () => {
        let time = getCookie('update_time');
        let update = false;
        if(time !== null){
            let timeCheck = parseInt(time);
            let timeNow = new Date().getTime();
            if(timeNow - timeCheck > 86400){
                update = true;
            }
        }
        if(update) {
            setCookie('update_time', String(new Date().getTime()), 7)
            let cookieList = ['coins', 'gender', 'menu', 'role', 'token', 'use_coins', 'tariff'];
            cookieList.forEach((cookieName) => {
                let tmpCookieVal = getCookie(cookieName);
                if (tmpCookieVal !== null) {
                    setCookie(cookieName, tmpCookieVal, 7)
                }
            })
        }
    }

    let { user_id } = useParams()
    const { filter } = useAppSelector(state => state.cabinet)
    const [submitData, profile] = useSubmitDataVkMutation()
    const [submitLikedPerson, LikedPerson] = useSubmitDataMutation()
    //const uid = getCookie('uid')
    const role = getCookie('role');
    if(role === null){
        const token = getCookie('token');
        if(token === null) {
            console.log('missing token');
            let navigate = useNavigate();
            navigate('/login', { replace: true });
        }
    }else{
        updateCookieTime();
    }
    var url = 'profiles.php?get=vk';
    if(role === 'ok_admin'){
        url = 'profiles.php?for=admin&get=vk';
    }
    const { data: profilesData, isSuccess, isLoading, error } = useGetApiVkQuery(url);
    /*const { data: profiles, isSuccess, isLoading, error } = useGetApiVkQuery(url) as {
        data?: VkData;
        isSuccess: boolean;
    };*/
    if(error) {
        try {
            //@ts-ignore
            if (profiles.error.status == 401) {
                let navigate = useNavigate();
                navigate('/login', {replace: true});
            }
        }catch (e) {}
    }
    const profiles = profilesData; // || { status: false, total_count: 0, items: [] };

    const [user, setUser] = useState<VkUser>();
    const [open, setOpen] = useState(false);
    const whois = useWhoIs();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const addLikedPerson = (likedPersonID: string, callback: any) => {
        let fd = new FormData()
        fd.append('vk', likedPersonID)
        submitLikedPerson({ name: 'cabinet/favorites.php', data: fd }).then((answer:any) => {
            if(answer.data){
                try {
                    callback(answer.data);
                }catch (e) {}
            }
        })
    }

    function checkActiveFilter(ff: any){
        for (const dat in ff) {
            if(typeof dat !== 'undefined')
                return true;
        }
        return false;
    }

    var userName: string = '';
    var userNameLoaded: boolean = false;

    useEffect(() => {
        let fd = new FormData()
        fd.append('user_id', user_id ? user_id : '')

        submitData({ name: 'profile.php', data: fd }).then((answer:any) => {
            if(answer.data && answer.data[0]){
                if(answer.data[0].firstname) {
                    userName = answer.data[0].firstname;
                    userNameLoaded = true;
                }
            }
        })

    }, [user_id])

    function convertToDate(dateString: string) { // 2000-06-07 - june, 2002-09-01 - sempt
        //  Convert a "yyyy-mm-dd" string into a Date object
        let d = dateString.split("-");
        let dat = new Date(d[0] + '/' + d[1] + '/' + d[2]);
        return dat;
    }


    const mygender = getCookie('gender');
    const printWord = () => {
        if(profiles && profiles.total_count) {
            if (mygender == 'm') {
                return pluralizeRus(profiles.total_count, ['', 'ши', 'ш'])
            }
            return pluralizeRus(profiles.total_count, ['', 'а', 'ов'])
        }else{
            return '';
        }
    }

    const printIzVsego = () => {
        if(profiles && profiles.total_summary && profiles.total_summary > 0) {
            return <div style={{display: 'inline'}}> из <strong>{profiles.total_summary}</strong> возможных</div>
        }else{
            return '';
        }
    }
    const printName = () => {
        try {
            //@ts-ignore
            if(profile.data[0].rule_always_coins) {
                //@ts-ignore
                localStorage.setItem('use_coins', profile.data[0].rule_always_coins)
            }
        }catch (e) {}
        //@ts-ignore
        if(profile.data && profile.data[0].firstname){
            //@ts-ignore
            return (', '+profile.data[0].firstname);
        }
        return '';
    }

    const printId = () => {
        //@ts-ignore
        if(profile.data && profile.data[0].id){
            //@ts-ignore
            return <p style={{margin: '0'}}> ID вашего профиля №<strong>{profile.data[0].id}</strong>.</p>
        }
        return '';
    }

    const printTariffName = () => {
        //@ts-ignore
        if(profile.data[0].tariff_name){
            //@ts-ignore
            return <>{profile.data[0].tariff_name}</>;
        }
        return '';
    }

    const printActiveEmailWarning = () => {
        //@ts-ignore
        if(profile.data[0].active === '0'){
            //@ts-ignore
            return <Alert
                severity="error"
                sx={{ margin: '15px 20px 20px 15px' }}
            >
                Необходимо активировать аккаунт по ссылке в письме, проверьте папку спам если не получали код подтверждения.
            </Alert>
        }
        return '';
    }

    const printTariffUntil = () => {
        //@ts-ignore
        if(profile.data[0].tariff !== "0" && profile.data[0].tariff_until){
            //@ts-ignore
            let dateTariff = profile.data[0].tariff_until.split('-');
            //@ts-ignore
            return (<>, действует до&nbsp;<strong>{dateTariff[2]}.{dateTariff[1]}.{dateTariff[0]}</strong></>);
        }
        return null;
    }
    const printTariffUntilOnly = () => {
        //@ts-ignore
        if(profile.data[0].tariff_until){
            //@ts-ignore
            let dateTariff = profile.data[0].tariff_until.split('-');
            //@ts-ignore
            return (<><strong>{dateTariff[2]}.{dateTariff[1]}.{dateTariff[0]}</strong></>);
        }
        return null;
    }

    /*const printCoinsAvailable = () => {
        //@ts-ignore
        if(profile.tariff_coins){
            //@ts-ignore
            let coins = parseInt(profile.tariff_coins);
            if(coins > 0){
                return (<>
                    <br />Вам доступно: {coins} ключ{pluralizeRus(coins, ['', 'ча', 'ей'])}.
                </>)
            }else{
                return null
            }
        }
    }*/
    const printTariffInfo = () => {
        //@ts-ignore
        if(profile.data) {
            let coins = 0;
            //@ts-ignore
            if(profile.data[0].tariff_coins) {
                //@ts-ignore
                coins = parseInt(profile.data[0].tariff_coins);
            }
            //@ts-ignore
            if(profile.data[0].tariff_name !== 'FREEZE') {
                return (
                    <Typography component="div" style={{
                        margin: '5px 0 0 0',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            variant="dot"
                        >
                            <Avatar style={{
                                display: 'inline-flex',
                                backgroundColor: '#369397',
                                margin: '0 0 0 0',
                            }}><FavoriteBorderIcon/></Avatar>
                        </StyledBadge>
                        <div style={{display: 'inline-flex', flexDirection: 'column', margin: '0 0 0 10px'}}>
                            <div>Ваш тариф:&nbsp;<strong>{printTariffName()}</strong>{printTariffUntil()}</div>
                            <div>Вам доступно:&nbsp;
                                <strong>{coins}</strong>&nbsp;ключ{pluralizeRus(coins, ['', 'а', 'ей'])}</div>
                        </div>
                    </Typography>
                );
            }else{
                return (
                    <Typography component="div" style={{
                        margin: '5px 0 0 0',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            variant="dot"
                        >
                            <Avatar style={{
                                display: 'inline-flex',
                                backgroundColor: '#369397',
                                margin: '0 0 0 0',
                            }}><FavoriteBorderIcon/></Avatar>
                        </StyledBadge>
                        <div style={{display: 'inline-flex', flexDirection: 'column', margin: '0 0 0 10px'}}>
                            <div>Доступ к анкетам заморожен до:&nbsp;{printTariffUntilOnly()}</div>
                        </div>
                    </Typography>
                );
            }
        }else{
            return null;
        }
    }

    const printCurrentMonth = () => {
        const month = ["январь","февраль","март","апрель","май","июнь","июль","август","сентябрь","октябрь","ноябрь","декабрь"];
        const d = new Date();
        return month[d.getMonth()];
    }
    const printCardVk = (elem:VkUser) => {
        return (
            <Grid item xs={2} sm={3} md={3} lg={3} key={elem.id}>
                <Card>
                    <CardActionArea>
                        <FavoriteTwoToneIcon
                            // sx={elem.vip !== '0' ? { position: 'absolute', top: '2%', right: '2%', color: 'orange' } : { position: 'absolute', top: '2%', right: '2%'}}
                            sx={{
                                zIndex: 100,
                                position: 'absolute',
                                padding: '3px',
                                margin: '3px',
                                right: 0,
                                top: 0,
                                color: elem.is_favorite == "1" ? 'orange' : 'white',
                                cursor: 'pointer',
                                backgroundColor: 'rgba(128,128,128,0.5)',
                                fontSize: '32px',
                                borderRadius: '20px'
                            }}
                            onClick={(e) => {
                                var targetEvent = e.currentTarget;
                                addLikedPerson(elem.id, function(data: any){
                                    if(data.status && data.status == true) {
                                        try {
                                            if(data.added == true){
                                                targetEvent.style.color = 'orange';
                                            }else{
                                                targetEvent.style.color = 'white';
                                            }
                                        }catch (e) {}
                                    }
                                });
                            }}
                        />
                        <CardMedia
                            onClick={() => {handleOpen(); setUser(elem)}}
                            component="img"
                            loading="lazy"
                            height="140"
                            image={getImagesSrcVk(elem)}
                            alt="Фото профиля"
                            data-id={elem.id}
                            sx={{ '&': { objectFit: 'cover', backgroundSize: '100% auto' }, minHeight: '28vh'}}
                        />
                        {printCompatible(elem.compatible)}
                        {printRegionLabel(elem.city)}
                        {printLeftBottom('id'+elem.id, elem.id)}
                    </CardActionArea>
                </Card>
            </Grid>
        )
    }

    const printNew = () => {
        //let newProfiles:VkUser[] = profiles.data;
        /*filterationArr?.filter(elem => elem.vip === '0' && elem.isnew &&
            elem.gender[0] !== ((profile?.data && typeof profile?.data !== "string")
                ? profile?.data[0].gender[0] : "Ж"))
            .map(elem => {
                newProfiles.push(elem);
            })*/
        if(profiles && profiles.items && profiles.items.length > 0) {
            /*
            <Typography component='div' style={{
                width: 'calc(100% - 30px)',
                height: '1px',
                border: '1px solid #cfe7ff',
                backgroundColor: '#cfe7ff',
                borderRadius: '3px',
                margin: '15px 20px 5px 15px'
            }}/>
            */
            return (
                <>
                    <Alert
                      severity="info"
                      sx={{margin: '15px 20px 20px 15px'}}
                    >Новые профили за <strong>{printCurrentMonth()}</strong></Alert>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 9, md: 12, lg: 16}}
                          sx={{justifyContent: 'center'}}>
                        {
                            profiles.items.map(elem => {
                                return printCardVk(elem)
                            })
                        }
                    </Grid>
                </>
            );
        }
        return null;
    }

    return(
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            {isSuccess && <Alert
              severity="success"
              sx={{ margin: '15px 20px 20px 15px' }}
            >
                <p style={{margin: '0'}}>Здравствуйте{profile.data && printName()}!</p>
                {profile.data && printId()}
                <p style={{margin: '0'}}>На&nbsp;текущий момент с&nbsp;вами совместимо <strong>{profiles && profiles.total_count ? profiles.total_count : 0}</strong> партнер{printWord()}{printIzVsego()}.{printTariffInfo()}</p>
            </Alert>}
            {isSuccess && profile.data && printActiveEmailWarning()}
            <Box style={{padding: '15px', flex: '1'}}>
                {isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
                {user ?
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        scroll={'body'}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogContent  >
                            <PrintVkUser userData={user} />
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Cancel</Button> */}
                            <Button onClick={handleClose}>Закрыть</Button>
                        </DialogActions>
                    </Dialog>
                    : null }
                    {/* <T>Нажмите на звездочку в углу фото, что бы добавить в избранное:</T> */}
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 9, md: 12, lg: 16 }}
                    sx={{justifyContent: 'center'}}>
                    {/*profile.data &&
                        filterationArr?.filter(elem => elem.vip === '1' &&
                            elem.gender[0] !== ((profile?.data && typeof profile?.data !== "string")
                                ? profile?.data[0].gender[0] : "Ж") )
                        .map(elem => {
                            return printCard(elem)
                        })
                    */}
                    {/*
                    .sort((a, b) => {
                            //console.log('a.birthday',a.birthday);
                            if(a.birthday && b.birthday) {
                                return (new Date(
                                    a.birthday.toString()
                                    //.split('-').reverse().join('-')
                                ).getTime() >= new Date(
                                    b.birthday.toString()
                                    //.split('-').reverse().join('-')
                                ).getTime()) ? -1 : 1;
                            }
                            return -1;
                         })
                    */}
                </Grid>
                {isSuccess && printNew()}
                <Typography component='div' style={{
                    width: 'calc(100% - 30px)',
                    height: '1px',
                    border: '1px solid #cfe7ff',
                    backgroundColor: '#cfe7ff',
                    borderRadius: '3px',
                    margin: '15px 20px 5px 15px'
                }}/>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 9, md: 12, lg: 16 }}
                    sx={{ justifyContent: 'center' }}>
                    {/*filterationArr?.filter(elem => elem.gender[0] !== ((profile?.data && typeof profile?.data !== "string")
                            ? profile?.data[0].gender[0] : "Ж"))
                        .map(elem => {
                            return printCard(elem)
                        })*/
                    }
                </Grid>
                <Outlet />
            </Box>
            { PrintFooterAstrodating() }
        </Box>
    )
}

export default CabinetVK
