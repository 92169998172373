import { useEffect, useState } from "react";
import { getCookie, setCookie } from "../functions/Cookie";
import { useGetApiQuery } from "../services/goroskop";
import { Data } from "../types/data";
import { useAppSelector } from "./hooks";

export default function useWhoIs(): {
    Account: Data,
    whoIs: 'admin' | 'user' | "ok_dok" | "ok_mainDok" | 'unlogined' | 'ok_admin' | 'ok_user'
} {
    const { data, isSuccess } = useGetApiQuery('auth.php');
    const { easyRole, Account } = useAppSelector(state => state.main);

    // Читаем роль из cookie, если она есть
    const cookieRole = getCookie('role');
    //console.log('cookieRole',cookieRole)

    // Изначально фиксируем whoIs на основе cookie (если значение отсутствует – будет 'unlogined')
    const [whoIs, setWhoIs] = useState({
        Account: {} as Data,
        whoIs: switchRole(cookieRole)
    });

    function switchRole(role: any): 'admin' | 'user' | 'unlogined' {
        switch (role) {
            case "ok_admin":
            case "ok_mainModer":
            case "ok_Moder":
            case "ok_mainDok":
            case "ok_dok":
            case "consideration":
                return 'admin';
            case "ok_user":
                return 'user';
            default:
                return 'unlogined';
        }
    }

    useEffect(() => {
        if (whoIs.whoIs !== 'unlogined' && Account && (whoIs.whoIs !== easyRole || Object.keys(whoIs.Account).length === 0)) {
            //console.log('archive and renew of whoIs',whoIs)
            setWhoIs({
                Account: Account,
                whoIs: easyRole
            });
        }else {
            const token = getCookie('token');
            if (token) {
                // Пользователь не авторизован: обращаемся к API для получения данных
                try {
                    if (isSuccess && data && data[0] && data[0].role &&
                        (whoIs.whoIs !== switchRole(data[0].role) || whoIs.Account !== data[0])
                    ) {
                        // Если передан тариф, сохраняем его в cookie
                        if (data[0] && data[0]['tariff'] && typeof data[0]['tariff'] !== "undefined") {
                            //@ts-ignore
                            let tariff: number | string = parseInt(data[0]['tariff']);
                            if (tariff > 0) {
                                //@ts-ignore
                                tariff = tariff.toString();
                                setCookie('tariff', tariff, 7);
                            }
                        }
                        setWhoIs({
                            Account: data[0],
                            whoIs: switchRole(data[0].role)
                        });
                        // console.log('68 whoIs data',data)
                        // console.log('68 whoIs',whoIs)
                    }
                    // Если в Redux доступны данные аккаунта – синхронизируем роль с easyRole
                    if (Account && whoIs.whoIs !== easyRole) {
                        // console.log('72 whoIs',whoIs)
                        setWhoIs({
                            Account: Account,
                            whoIs: easyRole
                        });
                    }
                } catch (e) {
                    console.error("Error in useWhoIs effect:", e);
                }
            }
        }
    }, []); // Эффект выполняется только один раз при монтировании

    return whoIs;
}
