import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// createApi, BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError
import {Data, DataPagination, logData, VkUser} from '../types/data'
import {QuizType, VkData} from '../types/quiz'
import { SubmitDataType } from '../types/submitData'
import { getCookie } from "../functions/Cookie"
import { useNavigate } from "react-router-dom";

interface Post {
    id: number
    name: string
}
type PostsResponse = Post[]

// http://mui/api/
export const goroskopAPI = createApi({
    reducerPath: 'goroskopApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: (headers, { getState }) => {
            //const token = (getState() as RootState).auth.token
            const token = getCookie('token');

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    tagTypes: ["GET", 'UPDATE', 'Posts'],
    endpoints: builder => ({
        // getUserById: builder.query<any[], string>({
        //     query: id => `lines/${}`
        // }),

        getApi: builder.query<Data[], string>({ // DataPagination
            query: (name) => `${name}`,
            providesTags: (result) => {
                return result ? [...result.map(({ id }) => ({ type: 'GET' as const, id })), 'GET']
                    : [{ type: 'GET', id: 'List' , request: result}]
            }
        }),
        getLog: builder.query<logData[], string>({ // DataPagination
            query: (name) => `${name}`,
            providesTags: (result) => {
                return result ? [...result.map(({ id }) => ({ type: 'GET' as const, id })), 'GET']
                    : [{ type: 'GET', id: 'List' , request: result}]
            }
        }),
        submitData: builder.mutation<string | QuizType[], SubmitDataType>({
            query: ({ name, data }: SubmitDataType) => ({
                url: `/${name}`,
                method: 'POST',
                body: data
            })
        }),
        SubmitFavorites: builder.mutation<string | {users:QuizType[],vk:VkUser[]}, SubmitDataType>({
            query: ({ name, data }: SubmitDataType) => ({
                url: `/${name}`,
                method: 'POST',
                body: data
            })
        }),
        submitDataWithRerender: builder.mutation<string | QuizType[], SubmitDataType>({
            query: ({ name, data }: SubmitDataType) => ({
                url: `/${name}`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['GET']
        }),
        SubmitStatus: builder.mutation<string, string | FormData>({
            query: (post) => ({
                url: `/status_save.php`,
                method: 'POST',
                body: post
            }),
        }),
        RemoveVkClient: builder.mutation<string, string | FormData>({
            query: (post) => ({
                url: `/delete_vk.php`,
                method: 'POST',
                body: post
            }),
        }),
        AuthAccount: builder.mutation<{ Account: Data, whoIs: string, token: string, status: boolean, msg: string, type: string, gender: number, color: number}, SubmitDataType>({
            query: ({ name, data }: SubmitDataType) => ({
                url: `/${name}`,
                method: 'POST',
                body: data
            })
        }),
        submitDataVk: builder.mutation<string | VkData, SubmitDataType>({
            query: ({ name, data }: SubmitDataType) => ({
                url: `/${name}`,
                method: 'POST',
                body: data
            })
        }),
        getApiVk: builder.query<VkData, string>({ // DataPagination
            query: (name) => `${name}`,
            providesTags: (result) => {
                console.log('result',result);
                return result ? [...result.items.map(({ id }) => ({ type: 'GET' as const, id })), 'GET']
                    : [{ type: 'GET', id: 'List' , request: result}]
            }
        }),
    }),

})

export const {
    useGetApiQuery,
    useGetLogQuery,
    useAuthAccountMutation,
    useGetApiVkQuery,
    useSubmitDataVkMutation,
    useSubmitDataMutation,
    useSubmitFavoritesMutation,
    useSubmitStatusMutation,
    useSubmitDataWithRerenderMutation,
    useRemoveVkClientMutation
} = goroskopAPI
