import React, { useState, useRef } from 'react';
import axios from 'axios';
import {
    Box,
    TextField,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    IconButton,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { SearchResponse, SearchResultData, SearchUser } from '../../../types/SearchResponse';
import "../../../search.css"

const Search: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState<SearchResponse>({ success: false });
    const [page, setPage] = useState(1);
    const [perPage] = useState(10);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const searchContainerRef = useRef<HTMLDivElement | null>(null);

    // Handle search input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // Handle search button click or Enter key press
    const handleSearchClickOrEnter = () => {
        setPage(1);
        fetchSearchResults(searchQuery, 1, perPage);
        setIsSearchOpen(true);
    };

    // Handle pagination change
    const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
        fetchSearchResults(searchQuery, newPage, perPage);
    };

    // Fetch search results from the backend
    const fetchSearchResults = async (query: string, currentPage: number, limit: number) => {
        try {
            const response = await axios.get<SearchResponse>('/api/search/', {
                params: {
                    q: query,
                    page: currentPage,
                    per_page: limit
                }
            });
            setResults(response.data);

            // Если поиск не дал результатов
            if (!response.data.success || !response.data.data || response.data.data.total === 0) {
                console.log('Поиск не дал результатов.');
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    // Handle close action
    const handleCloseAction = () => {
        setIsSearchOpen(false);
        setSearchQuery('');
        setResults({ success: false });
    };

    // Close search container if clicked outside of it
    const handleClickOutside = (event: MouseEvent) => {
        if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
            handleCloseAction();
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getStatus = (status: number, tariff: number) => {
        let name: string;
        switch(status){
            case -2: name = 'Удален'; break;
            case 0: name = 'Главный модератор'; break;
            case 1: name = 'Модератор 1'; break;
            case 2: name = 'Модератор 2'; break;
            case 3: name = 'Модератор 3'; break;
            case 4: name = 'Модератор 4'; break;
            case 5: name = 'Модератор 5'; break;
            case 6: name = 'Модератор 6'; break;
            case 7: name = 'Модератор 7'; break;
            case 8: name = 'Модератор 8'; break;
            case 9: name = 'Модератор 9'; break;
            case 10: name = 'Модерация админа'; break;
            case 28: name = 'На обработке'; break;
            case 29:
                if(tariff > 0 && tariff !== 14 && tariff !== 15 && tariff !== 16){
                    name = 'Активный поиск';
                }else if (tariff == 0 || tariff == 14 || tariff == 15 || tariff == 16){
                    name = 'Пассивный поиск';
                }else{
                    name = 'Неизвестный статус ('+status+'/'+tariff+')';
                }
                break;
            case 30: name = 'Архив'; break;
            case 32: name = 'Корзина'; break;
            case 50: name = 'Линия 3'; break;
            case 49: name = 'Личная модерация ДОК'; break;
            case 51: name = 'ДОК 1'; break;
            case 52: name = 'ДОК 2'; break;
            case 53: name = 'ДОК 3'; break;
            case 54: name = 'ДОК 4'; break;
            case 55: name = 'ДОК 5'; break;
            case 56: name = 'ДОК 6'; break;
            case 57: name = 'ДОК 7'; break;
            case 58: name = 'ДОК 8'; break;
            case 59: name = 'ДОК 9'; break;
            case 61: name = 'Модератор ОО 1'; break;
            case 62: name = 'Модератор ОО 2'; break;
            case 63: name = 'Модератор ОО 3'; break;
            case 64: name = 'Модератор ОО 4'; break;
            case 65: name = 'Модератор ОО 5'; break;
            case 66: name = 'Модератор ОО 6'; break;
            case 67: name = 'Модератор ОО 7'; break;
            case 68: name = 'Модератор ОО 8'; break;
            case 69: name = 'Модератор ОО 9'; break;
            default:
                name = 'Неизвестный статус (' + status + ')';
                break;
        }
        return name;
    }

    return (
        <Box className="page-container" sx={{padding: '0 0 0 8px'}}>
            <TextField
                variant="outlined"
                value={searchQuery}
                onChange={handleInputChange}
                placeholder="Поиск..."
                margin="normal"
                size="small"
                InputProps={{
                    sx: { backgroundColor: 'white', fontSize: '0.875rem', marginTop: '-7px' },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleSearchClickOrEnter}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearchClickOrEnter();
                    }
                }}
            />

            {isSearchOpen && results.success && results.data && (
                <Box ref={searchContainerRef} className="search-container">
                    <IconButton onClick={handleCloseAction} className="search-closeIcon" sx={{ position: 'absolute', width: '42px', height: '42px', right: '10px', top: '10px', zIndex: 3, bgcolor: 'white', border: '1px solid grey', borderRadius: '50%' }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ p: 1, color: '#333333' }}>
                        Найдено <strong>{results.data.total}</strong> пользователей
                    </Typography>

                    <TableContainer className="search-table">
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ p: 1, pl: 32 }}></TableCell>
                                    <TableCell>Имя</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Телефон</TableCell>
                                    <TableCell>VK</TableCell>
                                    <TableCell>ДР</TableCell>
                                    <TableCell>Статус</TableCell>
                                    <TableCell>Тариф</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.data.results.map((user: SearchUser) => (
                                    <TableRow key={user.id}>
                                        <TableCell sx={{ p: 1, pl: 32 }}>
                                            <a href={`/admin/profile/${user.id}`}>
                                                <EditIcon className='search-editIcon' />
                                            </a>
                                        </TableCell>
                                        <TableCell>{user.firstname}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.phone}</TableCell>
                                        <TableCell>{user.vk}</TableCell>
                                        <TableCell>{new Date(user.birthd).toLocaleDateString()}</TableCell>
                                        <TableCell>{getStatus(user.status, user.tariff)}</TableCell>
                                        <TableCell>{user.tariff_name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Pagination
                        count={results.data.total_pages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Search;
