import {FC, useCallback, useState} from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { useGetApiVkQuery } from "../../../services/goroskop";
import EnhancedTableVK from "./../constituents/TableVk";

const ClientsVk : FC = () => {
    const { path, name } = useAppSelector(state => state.adminSlice);

    // Состояние для текущей страницы и количества строк на странице
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(500);

    // Функции обработки изменения страницы и количества строк на странице
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Перейти на первую страницу при изменении количества строк на странице
    };

    // Fetch data for the current page
    const { data, error, isLoading, currentData } = useGetApiVkQuery(`clients_vk.php?page=${page + 1}&rowsPerPage=${rowsPerPage}`);

    let newDate = data

    const MemoizedTable = useCallback(() =>
            <>
                {data && (
                    <EnhancedTableVK
                        data={newDate ? newDate : data}
                        nameOfTable={name}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </>
        , [newDate, data, name, page, rowsPerPage])

    return(
        <>
            {error && <h1>oops, er: {error}</h1>}
            {isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            <MemoizedTable />
        </>
    )
}

export default ClientsVk
