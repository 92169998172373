import { Box,Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
//import * as React from "react";
//import AliceCarousel from "react-alice-carousel";
import { VkUser } from '../../types/data'
import T from "../quiz/OnlyText";
import 'react-alice-carousel/lib/alice-carousel.css';
import Carousel from "../forAll/Carousel";
import regionCodes from "../forAll/regions";
import {QuizType} from "../../types/quiz";
//import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TextureIcon from '@mui/icons-material/Texture';
import CloseIcon from '@mui/icons-material/Close';
import '../../userlist.css';
import {useSubmitDataMutation} from "../../services/goroskop";

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {getCookie, setCookie} from "../../functions/Cookie";
import { useSnackbar } from "notistack"
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { printRegionName } from "../../functions/printRegion";
import { printUserInfo } from "./constituents/printUserInfo";
import React, { useCallback, useState } from 'react'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Modal from '@mui/material/Modal';
import useWindowDimensions from '../../functions/windowDimensions'

const Transition = React
    
    .forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PrintVkUser: React.FC<{userData: VkUser}> = ({userData}) => {
console.log('printVk', userData);
    const [
        user = userData,
        setUser
    ] = React.useState<VkUser>();

    const canUseCoins = (getCookie('use_coins') === '1' ? true : false);


    const sliderPrepare = () => {
        //userData.images.split(',').map(item => {
        let photo = (userData.photo_max ? userData.photo_max : (userData.photo ? userData.photo : ''))
        let imagesArr = [photo];
        let newImagesArr = [];
        for (let i = 0; i < imagesArr.length; i++) {
            let infoSrc = '/images/noImg.jpg';
            let infoAlt = userData.first_name + '-' + (i+1);
            if (imagesArr[i] == 'hidden') {
                switch (userData.gender) {
                    case 'М':
                        infoSrc = '/images/hide-man-photo.jpg';
                        break;
                    case 'Ж':
                        infoSrc = '/images/hide-woman-photo.jpg';
                        break;
                }
                newImagesArr.push({src: infoSrc, alt: 'hidden'});
            } else {
                if (imagesArr[i] && imagesArr[i].length > 0 && imagesArr[i] !== 'null') {
                    infoSrc = `${imagesArr[i]}`;
                    newImagesArr.push({src: infoSrc, alt: infoAlt});
                } else {
                    if(i == 0) {
                        infoSrc = '/images/noImg.jpg';
                        newImagesArr.push({src: infoSrc, alt: 'hidden'});
                    }
                }
            }
        }
        return newImagesArr;
    }
    var sliderItems = sliderPrepare();
    const slider = Object.values(sliderItems).map(item => {
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };
        const { height, width } = useWindowDimensions();
        return (
            <React.Fragment>
                <img
                    onClick={handleOpen}
                    role='img'
                    key={item.src}
                    src={item.src}
                    style={{ maxWidth: '45%', marginLeft: '27.5%', cursor: 'zoom-in' }}
                    alt={item.alt}
                    loading="lazy"
                />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{
                        margin: '0 auto 0 auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        width: 'fit-content',
                        height: '100%'
                    }} onClick={handleClose}>
                        <div style={{
                            display: 'block',
                            position: 'relative'
                        }}>
                            <img
                                style={{
                                    maxWidth: width,
                                    maxHeight: height,
                                    margin: '0 auto 0 auto',
                                    display: 'block',
                                    flexShrink: 0,
                                    objectFit: 'cover',
                                    cursor: 'zoom-out'
                            }}
                                alt={item.alt}
                                src={item.src}
                                onClick={handleClose}
                            />
                            <div style={{
                                position: 'absolute',
                                top: '0.6em',
                                right: '0.6em',
                                padding: '0.6em 0.6em 0.325em 0.6em',
                                backgroundColor: '#ffffff',
                                borderRadius: '50%',
                                cursor: "pointer"
                            }} onClick={handleClose}><CloseIcon /></div>
                        </div>
                    </Box>
                </Modal>
            </React.Fragment>
        )
    })

    /*function ChildModalSlider() {
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };

        return (
            <React.Fragment>
                <Button onClick={handleOpen}>Open Child Modal</Button>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ width: 200 }}>
                        <h2 id="child-modal-title">Text in a child modal</h2>
                        <p id="child-modal-description">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                        </p>
                        <Button onClick={handleClose}>Close Child Modal</Button>
                    </Box>
                </Modal>
            </React.Fragment>
        );
    }*/

    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [submitData, { }] = useSubmitDataMutation()
    // const [user, setUser] = React.useState<Data>();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const useCoin = (userId:string) => {
        if(userId){
            let fD = new FormData();
            fD.append('id', userId);
            submitData({name: 'use_coin.php', data: fD})
                .then(response => {
                    //console.log('response',response)
                    // @ts-ignore
                    let res = response.data;
                    if (res && typeof (res.status) === 'boolean') {
                        if (res.msg && res.msg.length > 0) {
                            if(res.status === true && res.user){
                                setUser(res.user);
                            }
                            enqueueSnackbar(res.msg, {variant: (res.status === true ? 'success' : 'error')});
                        } else {
                            enqueueSnackbar('Ошибка открытия профиля №2', {variant: 'error'});
                        }
                    }
                    if (typeof (res.coins) !== "undefined" && res.coins >= 0) {
                        setCookie('coins', (res.coins).toString(), 7);
                    }
                    handleClose();
                })
        }else{
            enqueueSnackbar('Ошибка открытия профиля №1', {variant: 'error'});
        }
    }

    const doWorkCoin = (userId: string) => {
        if(typeof(userId) === 'string') {
            useCoin(userId)
        } else {
            handleClose()
        }
    }

    const alertDialogSlide = (userId: string, compatible: number, coins: number, flagShowBtn: boolean) => {
        const canUseCoins = (localStorage.getItem('use_coins') === "1");
        if(flagShowBtn) {
            let openAccess = flagShowBtn && (coins > 0);
            if(canUseCoins !== true){
                openAccess = false;
            }
            return (
                <React.Fragment>
                    <T sx={{textAlign: 'center'}}>
                        <Button style={{
                            borderRadius: 35,
                            backgroundColor: (openAccess ? '#673ab7' : '#d1c4e9'),
                            padding: "6px 18px",
                            fontSize: "18px"
                        }} variant="contained" onClick={handleClickOpen}>
                            {'Открыть профиль'}
                            <VpnKeyIcon style={{
                                margin: '-4px 0 0 5px'
                            }}/>
                        </Button>
                    </T>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{openAccess
                            ? 'Вы уверены что хотите открыть этот профиль?'
                            : 'Вашего тарифа недостаточно чтобы использовать ключ на этом профиле'
                        }</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {openAccess
                                    ? 'Вы уверены, что хотите открыть профиль совместимостью ' + compatible + '%? На это действие нужно использовать 1 ключ, Вам доступно: ' + coins + '.'
                                    : 'Обратитесь к нашему менеджеру в бюро счастливых знакомств!'
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>{openAccess ? 'Отмена' : 'Закрыть'}</Button>
                            {
                                openAccess
                                    ? <Button onClick={() => doWorkCoin(userId)}>Открыть</Button>
                                    : null
                            }
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            );
        }
    }

    const printRelation = (relation: string) => {
        switch (relation){
            case '1': return 'не женат/не замужем'; break;
            case '2': return 'есть друг/есть подруга'; break;
            case '3': return 'помолвлен/помолвлена'; break;
            case '4': return 'женат/замужем'; break;
            case '5': return 'всё сложно'; break;
            case '6': return 'в активном поиске'; break;
            case '7': return 'влюблён/влюблена'; break;
            case '8': return 'в гражданском браке'; break;
            case '0': return 'не указано'; break;
            default:  return '-'; break
        }
    }

    const printUseCoin = (userId:string, compatible:number, hide_btn: boolean | undefined, isnew: boolean | undefined) => {
        let coins = getCookie('coins');
        let coins2 = 0;
        if(coins){
            coins2 = parseInt(coins);
        }
        let flagShowBtn = (hide_btn === false);
        if(!isnew) {
            return alertDialogSlide(
                userId,
                compatible,
                coins2,
                flagShowBtn
            );
        }else{
            return null;
        }
    }

    return(
        <>
            <Box sx={{

                '& > p': {
                    userSelect: 'none',
                    lineHeight: '1.4'
                },

            }}>
                <DialogTitle id="scroll-dialog-title">{user.first_name == 'hidden' ? 'Пользователь' : (user.first_name +' '+ user.last_name)}</DialogTitle>
                <Carousel items={slider} defIndex={'0'} dataId={user.id} />
                {user.compatible || user.compatible >= 0 ? printUserInfo('Ваш уровень астрологической совместимости',user.compatible+'%', false) : null}
                {printUserInfo('Внешний код', user.id, false)}
                {user.region_id ? printUserInfo('Регион', (user.region_id !== null && user.region_id != "0" ? user.region_id : '-'), false) : null}
                {user.city ? printUserInfo('Город', printRegionName(user.city), false) : null}
                {user.birthday ? printUserInfo('Дата рождения',
                    //@ts-ignore
                    user.birthday,
                    false) : null}
                {!user.birthday && user.bdate ? printUserInfo('Дата рождения',
                    //@ts-ignore
                    user.bdate,
                    false) : null}
                {user.sign_m ? printUserInfo('sign_m', user.sign_m, false) : (user.zodiak ? printUserInfo('Знак по западной астрологии', user.zodiak, false) : null)}
                {user.sign_y ? printUserInfo('sign_y', user.sign_y, false) : (user.birthyear ? printUserInfo('Знак по восточной астрологии', user.birthyear, false) : null)}
                {user.vk_id ? printUserInfo('Ссылка на страницу вконтакте', (user.vk_id !== 'hidden' ? ('https://vk.com/id'+user.vk_id) : 'hidden'), true) : null}
                {user.relation ? printUserInfo('Семейное положение', printRelation(user.relation), false) : null}
                {user.gender ? printUserInfo('Пол', user.gender, false) : null}
                {user.regdate ? printUserInfo('Дата вступления', user.regdate, false) : null}
            </Box>
        </>
    )
}

export default PrintVkUser
