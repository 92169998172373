import { FC, useEffect, useState } from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import { useGetApiQuery, useSubmitDataMutation } from "../../services/goroskop";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid, Modal } from '@mui/material';
import User from "./user";
import { Data } from "../../types/data";
import { Box } from "@mui/system";
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useAppSelector } from "../../hooks/hooks";
import useWhoIs from "../../hooks/useWhoIs";
import {deleteCookie, getCookie, setCookie} from "../../functions/Cookie";
import '../../profiles.css'
import Alert from '@mui/material/Alert';

//import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import {printCompatible, getImagesSrc, printRegionLabel, printLock, printLeftBottom} from "./constituents/cabinetInfo"
import {PrintFooterAstrodating} from './constituents/printFooter'
import DialogPassword from "./constituents/dialogPassword";


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: '-1px',
            left: '-1px',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const pluralizeRus = (n: number, forms: string[]) => {
    return n % 10 == 1 && n % 100 != 11
        ? forms[0]
        : (n % 10 >= 2 && n % 10 <= 4
        && (n % 100 < 10
            || n % 100 >= 20) ? forms[1] : forms[2]);
}

const Cabinet: FC = () => {

    const updateCookieTime = () => {
        let time = getCookie('update_time');
        let update = false;
        if(time !== null){
            let timeCheck = parseInt(time);
            let timeNow = new Date().getTime();
            if(timeNow - timeCheck > 86400){
                update = true;
            }
        }
        if(update) {
            setCookie('update_time', String(new Date().getTime()), 7)
            let cookieList = ['coins', 'gender', 'menu', 'role', 'token', 'use_coins', 'tariff'];
            cookieList.forEach((cookieName) => {
                let tmpCookieVal = getCookie(cookieName);
                if (tmpCookieVal !== null) {
                    setCookie(cookieName, tmpCookieVal, 7)
                }
            })
        }
    }

    let { user_id } = useParams()
    const { filter } = useAppSelector(state => state.cabinet)
    const [submitData, profile] = useSubmitDataMutation()
    const [submitLikedPerson, LikedPerson] = useSubmitDataMutation()
    //const uid = getCookie('uid')
    const role = getCookie('role');
    if(role === null){
        const token = getCookie('token');
        if(token === null) {
            console.log('missing token');
            let navigate = useNavigate();
            navigate('/login', { replace: true });
        }
    }else{
        updateCookieTime();
    }
    var url = 'profiles.php';
    if(role === 'ok_admin'){
        url = 'profiles.php?for=admin';
    }
    const profiles = useGetApiQuery(url);
    if(profiles.error) {
        try {
            //@ts-ignore
            if (profiles.error.status == 401) {
                let navigate = useNavigate();
                navigate('/login', {replace: true});
            }
        }catch (e) {}
    }

    const [filterationArr, setFilterationArr] = useState<Data[]>(profiles.data ?? []);
    const [user, setUser] = useState<Data>();
    const [open, setOpen] = useState(false);
    const whois = useWhoIs();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const addLikedPerson = (likedPersonID: string, callback: any) => {
        let fd = new FormData()
        fd.append('id', likedPersonID)
        submitLikedPerson({ name: 'cabinet/favorites.php', data: fd }).then((answer:any) => {
            if(answer.data){
                try {
                    callback(answer.data);
                }catch (e) {}
            }
        })
    }

    function checkActiveFilter(ff: any){
        for (const dat in ff) {
            if(typeof dat !== 'undefined')
                return true;
        }
        return false;
    }

    var userName: string = '';
    var userNameLoaded: boolean = false;

    useEffect(() => {
        let fd = new FormData()
        fd.append('user_id', user_id ? user_id : '')

        submitData({ name: 'profile.php', data: fd }).then((answer:any) => {
            if(answer.data && answer.data[0]){
                if(answer.data[0].firstname) {
                    userName = answer.data[0].firstname;
                    userNameLoaded = true;
                }
            }
        })

    }, [user_id])

    function convertToDate(dateString: string) { // 2000-06-07 - june, 2002-09-01 - sempt
        //  Convert a "yyyy-mm-dd" string into a Date object
        let d = dateString.split("-");
        let dat = new Date(d[0] + '/' + d[1] + '/' + d[2]);
        return dat;
    }

    React.useLayoutEffect(() => {
        if(filter) {
            //console.log('filter', filter);
            let multiFilter = {
                children_empty: ((typeof filter.children_empty != "undefined") && filter.children_empty != false),
                children: ((typeof filter.children != "undefined") && filter.children.length > 0),
                smoke_empty: ((typeof filter.smoke_empty != "undefined") && filter.smoke_empty != false),
                smoke: ((typeof filter.smoke != "undefined") && filter.smoke.length > 0),
            };
            let stat = {search: '', found: 0, mans: 0, womans: 0}
            if (!checkActiveFilter(filter)) {
                if (profiles.data) {
                    setFilterationArr(profiles.data)
                }
            } else {
                profiles.data && (
                    setFilterationArr(
                        profiles.data?.filter(e => {

                            /*const target = {};
                            let newFilter = Object.assign(target, filter);
                            if(typeof(newFilter.prefilter) !== 'undefined') {
                                delete newFilter.prefilter
                            }
                            const filterMap = Object.keys(newFilter);*/

                            const filterMap = Object.keys(filter);
                            return Object.values(filter).filter(el => !!el).length === Object.values(filter).map((value: any, index) => {
                                if (typeof (value) !== "undefined" && !!value) {// console.error('dev_cab_67', {i: index, v: value, exception: e});
                                    let targetValue = '';
                                    /*if(filterMap[index] == 'height_empty' || filterMap[index] == 'smoke_empty' || filterMap[index] == 'children_empty'){

                                    }else{
                                        try {
                                            if(typeof(value) === 'string'){
                                                value = value.trim()
                                            }
                                        }catch(e){
                                            console.error(e);
                                        }
                                    }
                                    if(filterMap[index] === 'birthyear'){
                                        console.warn('birthyear',value);
                                    }*/
                                    //console.log('filter', {i: index, is:filterMap[index], v: value, e: e});
                                    switch(filterMap[index]){
                                        case 'prefilter':{
                                            return true;
                                        }break;
                                        case 'heightFrom':
                                        case 'heightUntil':
                                            if ((filter.heightUntil || filter.heightFrom) ) {
                                                let tHeight = 0,
                                                    htFrom = Number(filter.heightFrom),
                                                    htUntil = Number(filter.heightUntil);
                                                // tHeight = parseInt(e.height);
                                                tHeight =  Number((e.height).replace( /(^.+\D)(\d+)(\D.+$)/i,'$2'));
                                                //console.log('tHeight',tHeight);
                                                if(tHeight > 0) {
                                                    if (!isNaN(htFrom) || !isNaN(htUntil)) {
                                                        if (!isNaN(htFrom) && !isNaN(htUntil)) {
                                                            if (htUntil < htFrom) {
                                                                htUntil = [htFrom, htFrom = htUntil][0];
                                                                /*let tmpDate = 0; tmpDate = htFrom; htFrom = htUntil; htUntil = tmpDate;*/
                                                            }
                                                        } else {
                                                            if (isNaN(htFrom) || htFrom <= 0) {
                                                                htFrom = 0;
                                                            }
                                                            if (isNaN(htUntil) || htUntil >= 500) {
                                                                htUntil = 500;
                                                            }
                                                        }
                                                        if (tHeight > 0) {
                                                            if (tHeight >= htFrom && tHeight <= htUntil) { // 4228
                                                                //console.log(e.birthday + ' - [' + e.vip + '] [' + e.id + '] ' + e.user_OutNum);
                                                                return true;
                                                            }else{
                                                                if(filter.height_empty && e.height.length == 0){
                                                                    return true;
                                                                }else {
                                                                    return false;
                                                                }
                                                            }
                                                        } else {
                                                            console.error('err YF' + e.id);
                                                        }
                                                    }
                                                }else{
                                                    if(filter.height_empty && e.height.length == 0){
                                                        return true;
                                                    }else {
                                                        return false;
                                                    }
                                                }
                                            }
                                            break;
                                        case 'birthdayFrom': // Выберите диапазон возрастов
                                        case 'birthdayUntil': // Выберите диапазон возрастов
                                            if ((filter.birthdayUntil || filter.birthdayFrom)) {
                                                try{
                                                    let bdTmp: string = '';
                                                    //console.log('230: e.birthday', e.birthday);
                                                    if(e.birthday) {
                                                        bdTmp = e.birthday.toString();
                                                        //console.log('233: bdTmp', bdTmp);
                                                        if (bdTmp && bdTmp.length > 2) {
                                                            let bd = 0,
                                                                bdFrom = Number(filter.birthdayFrom),
                                                                bdUntil = Number(filter.birthdayUntil);
                                                            /*console.log({
                                                                bdFrom: bdFrom,
                                                                bdUntil: bdUntil
                                                            })*/
                                                            if (!isNaN(bdFrom) || !isNaN(bdUntil)) {
                                                                if (!isNaN(bdFrom) && !isNaN(bdUntil)) {
                                                                    if (bdUntil < bdFrom) {
                                                                        bdUntil = [bdFrom, bdFrom = bdUntil][0];
                                                                        /*let tmpDate = 0; tmpDate = bdFrom; bdFrom = bdUntil; bdUntil = tmpDate;*/
                                                                    }
                                                                } else {
                                                                    if (isNaN(bdFrom) || bdFrom <= 1900) {
                                                                        bdFrom = 1900;
                                                                    }
                                                                    if (isNaN(bdUntil) || bdUntil >= 2200) {
                                                                        bdUntil = 2200;
                                                                    }
                                                                }
                                                                //console.log(bdTmp);
                                                                if (bdTmp.indexOf('.') !== (-1)) {
                                                                    bd = Number(bdTmp.split('.')[2]);
                                                                } else if (bdTmp.indexOf('-') !== (-1)) {
                                                                    bd = Number(bdTmp.split('-')[0]);
                                                                }
                                                                if (bd > 0) {
                                                                    if (bd >= bdFrom && bd <= bdUntil) { // 4228
                                                                        //console.log(e.birthday + ' - [' + e.vip + '] [' + e.id + '] ' + e.user_OutNum);
                                                                        return value;
                                                                    }
                                                                } else {
                                                                    console.error('err YF' + e.id);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }catch (e){
                                                    console.log('271 ---');
                                                }
                                            }
                                            break;
                                        case 'birthyear': // Знак зодиака по восточной астрологии - birthyear
                                            if (value.length > 0 && value.indexOf(e.birthyear) !== -1) {
                                                //console.log({'is':'vostoch(birthyear)', i:index, v:value, e: e});
                                                return true;
                                            }
                                            break;
                                        case 'zodiak': // Знак зодиака по западной астрологии - zodiak
                                            if ( value.length > 0 && value.indexOf(e.zodiak) !== -1) {
                                                //console.log({'is':'zapad(zodiak)', i:index, v:value, e: e});
                                                return true;
                                            }
                                            break;
                                        case 'city': // Регион поиска
                                            let curCity = parseInt(e.city)
                                            if ( !isNaN(curCity) && value.length > 0 && value.indexOf(curCity) !== -1) {
                                                return true;
                                            }
                                            break;
                                        case 'children':
                                        case 'children_empty':
                                            targetValue = e.children;
                                            if (typeof (value) !== 'boolean' && targetValue.length > 0) {
                                                if (targetValue == 'нет') { targetValue = 'Нет'; }
                                                if (targetValue == 'да') { targetValue = 'Да'; }
                                            }
                                            if( multiFilter.children_empty && multiFilter.children ){
                                                if(
                                                    ( targetValue.length == 0 || targetValue == null || targetValue == 'Не указано' ) ||
                                                    (targetValue == filter.children)
                                                ) {
                                                    return true
                                                }
                                            }else if((multiFilter.children_empty) && (targetValue.length == 0 || targetValue == null || targetValue == 'Не указано')) {
                                                return true;
                                            }else if(multiFilter.children && targetValue == filter.children){
                                                return true;
                                            }
                                            return false;
                                            break;
                                        case 'smoke':
                                            /*targetValue = e.smoke;
                                            if(targetValue.length > 0) {
                                                if(targetValue == 'нет'){ targetValue = 'Нет'; }
                                                if(targetValue == 'да'){ targetValue = 'Да'; }
                                                if (targetValue == value) {
                                                    return true;
                                                }else{
                                                    return false;
                                                }
                                            }else{
                                                // console.log({
                                                //     value: value,
                                                //     filter_smoke_empty: filter.smoke_empty,
                                                //     filter_smoke: filter.smoke
                                                // })
                                                if(filter.smoke_empty){
                                                    return true;
                                                }else{
                                                    return false;
                                                }
                                            } */
                                            targetValue = e.smoke;
                                            if (typeof (value) !== 'boolean' && targetValue.length > 0) {
                                                if (targetValue == 'нет') { targetValue = 'Нет'; }
                                                if (targetValue == 'да') { targetValue = 'Да'; }
                                            }
                                            if( multiFilter.smoke_empty && multiFilter.smoke ){
                                                if(
                                                    ( targetValue.length == 0 || targetValue == null || targetValue == 'Не указано' ) ||
                                                    (targetValue == filter.smoke)
                                                ) {
                                                    return true
                                                }
                                            }else if((multiFilter.smoke_empty) && (targetValue.length == 0 || targetValue == null || targetValue == 'Не указано')) {
                                                return true;
                                            }else if(multiFilter.smoke && targetValue == filter.smoke){
                                                return true;
                                            }
                                            return false;
                                            break;
                                        case 'height_empty':{
                                            if(filter.height_empty) {
                                                if (e.height.length == 0) {
                                                    return true
                                                } else {
                                                    if (
                                                        typeof (filter.heightFrom === 'undefined')
                                                        && typeof (filter.heightUntil === 'undefined')
                                                    ) {
                                                        return false;
                                                    }
                                                }
                                            }
                                        }break;
                                        case 'smoke_empty': {
                                            if (filter.smoke_empty && typeof(filter.smoke) === 'undefined') {
                                                targetValue = e.smoke;
                                                if (targetValue.length == 0) {
                                                    return true
                                                } else {
                                                    if (typeof (filter.smoke === 'undefined')) {
                                                        return false;
                                                    }
                                                }
                                            }
                                        }break;
                                        case 'gender':
                                            if(value.length > 0) {
                                                /*if (e.gender === 'М') {
                                                    stat.mans++
                                                } else if (e.gender === 'Ж') {
                                                    stat.womans++
                                                }*/
                                                if (e.gender == value) { // value -> filter_data
                                                    /*stat.search = e.gender;
                                                    stat.found++;*/
                                                    return true;
                                                }else{
                                                    return undefined;
                                                }
                                            }
                                            break;
                                        case 'langlove':{
                                            if (value.length > 0 && value.includes(e.langlove)) {
                                                return true;
                                            }
                                        }break;
                                        case 'langlove2':{
                                            if (value.length > 0 && value.includes(e.langlove2)) {
                                                return true;
                                            }
                                        }break;
                                        case 'targetsearch':{
                                            if (value.length > 0 && e.targetsearch == value){
                                                return true;
                                            }
                                        }break;
                                        case 'fromRegister': // d.m.Y
                                        case 'untilRegister': {
                                            if(e.registermonth_new) {
                                                let dateFrom: Date, dateUntil: Date, dateCheck: Date;
                                                if (filter.fromRegister)
                                                    dateFrom = convertToDate(filter.fromRegister);
                                                else {
                                                    dateFrom = new Date('2000/01/01'); // yyyy/mm/dd
                                                }

                                                if (filter.untilRegister)
                                                    dateUntil = convertToDate(filter.untilRegister);
                                                else
                                                    dateUntil = new Date('2500/12/31');

                                                dateCheck = convertToDate(e.registermonth_new);
                                                //registermonth_new - "2023-12-12 19:42:39" // yyyy-mm-dd
                                                /*let result123 = (dateCheck > dateFrom && dateCheck < dateUntil);

                                                if(result123)
                                                    console.log('252', {
                                                        reg: e.registermonth_new,
                                                        from: filter.fromRegister,
                                                        to: filter.untilRegister,
                                                        result: result123,
                                                    });*/

                                                return (dateCheck > dateFrom && dateCheck < dateUntil);
                                            }
                                        }break;
                                    }
                                }

                                return undefined

                            }).filter(el => !!el).length
                        })
                    )
                )
            }
            //console.warn('filter_stat',stat);
        }else if (profiles.data) {
            setFilterationArr(profiles.data)
        }
    }, [profiles.isSuccess, filter])

    const mygender = getCookie('gender');
    const printWord = () => {
        if (mygender == 'm') {
            return pluralizeRus(countPartners, ['', 'ши', 'ш'])
        }
        return pluralizeRus(countPartners, ['', 'а', 'ов'])
    }
    const printName = () => {
        try {
            //@ts-ignore
            localStorage.setItem('use_coins', profile.data[0].rule_always_coins)
        }catch (e) {}
        //@ts-ignore
        if(profile.data && profile.data[0].firstname){
            //@ts-ignore
            return (', '+profile.data[0].firstname);
        }
        return '';
    }

    const printId = () => {
        //@ts-ignore
        if(profile.data && profile.data[0].id){
            //@ts-ignore
            return <p style={{margin: '0'}}> ID вашего профиля №<strong>{profile.data[0].id}</strong>.</p>
        }
        return '';
    }

    const printTariffName = () => {
        //@ts-ignore
        if(profile.data[0].tariff_name){
            //@ts-ignore
            return <>{profile.data[0].tariff_name}</>;
        }
        return '';
    }

    const printActiveEmailWarning = () => {
        //@ts-ignore
        if(profile.data[0].active === '0'){
            //@ts-ignore
            return <Alert
                severity="error"
                sx={{ margin: '15px 20px 20px 15px' }}
            >Необходимо активировать аккаунт по ссылке в письме, проверьте папку спам если не получали код подтверждения.
            </Alert>
        }
        return '';
    }

    const printTariffUntil = () => {
        //@ts-ignore
        if(profile.data[0].tariff_until){
            //@ts-ignore
            let dateTariff = profile.data[0].tariff_until.split('-');
            //@ts-ignore
            return (<>, действует до&nbsp;<strong>{dateTariff[2]}.{dateTariff[1]}.{dateTariff[0]}</strong></>);
        }
        return null;
    }
    const printTariffUntilOnly = () => {
        //@ts-ignore
        if(profile.data[0].tariff_until){
            //@ts-ignore
            let dateTariff = profile.data[0].tariff_until.split('-');
            //@ts-ignore
            return (<><strong>{dateTariff[2]}.{dateTariff[1]}.{dateTariff[0]}</strong></>);
        }
        return null;
    }

    /*const printCoinsAvailable = () => {
        //@ts-ignore
        if(profile.tariff_coins){
            //@ts-ignore
            let coins = parseInt(profile.tariff_coins);
            if(coins > 0){
                return (<>
                    <br />Вам доступно: {coins} ключ{pluralizeRus(coins, ['', 'ча', 'ей'])}.
                </>)
            }else{
                return null
            }
        }
    }*/
    const printTariffInfo = () => {
        //@ts-ignore
        if(profile.data) {
            let coins = 0;
            //@ts-ignore
            if(profile.data[0].tariff_coins) {
                //@ts-ignore
                coins = parseInt(profile.data[0].tariff_coins);
            }
            //@ts-ignore
            if(profile.data[0].tariff_name !== 'FREEZE') {
                return (
                    <Typography component="div" style={{
                        margin: '5px 0 0 0',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            variant="dot"
                        >
                            <Avatar style={{
                                display: 'inline-flex',
                                backgroundColor: '#369397',
                                margin: '0 0 0 0',
                            }}><FavoriteBorderIcon/></Avatar>
                        </StyledBadge>
                        <div style={{display: 'inline-flex', flexDirection: 'column', margin: '0 0 0 10px'}}>
                            <div>Ваш тариф:&nbsp;<strong>{printTariffName()}</strong>{printTariffUntil()}</div>
                            <div>Вам доступно:&nbsp;
                                <strong>{coins}</strong>&nbsp;ключ{pluralizeRus(coins, ['', 'а', 'ей'])}</div>
                        </div>
                    </Typography>
                );
            }else{
                return (
                    <Typography component="div" style={{
                        margin: '5px 0 0 0',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            variant="dot"
                        >
                            <Avatar style={{
                                display: 'inline-flex',
                                backgroundColor: '#369397',
                                margin: '0 0 0 0',
                            }}><FavoriteBorderIcon/></Avatar>
                        </StyledBadge>
                        <div style={{display: 'inline-flex', flexDirection: 'column', margin: '0 0 0 10px'}}>
                            <div>Доступ к анкетам заморожен до:&nbsp;{printTariffUntilOnly()}</div>
                        </div>
                    </Typography>
                );
            }
        }else{
            return null;
        }
    }

    let countPartners = filterationArr.length;

    const printCurrentMonth = () => {
        const month = ["январь","февраль","март","апрель","май","июнь","июль","август","сентябрь","октябрь","ноябрь","декабрь"];
        const d = new Date();
        return month[d.getMonth()];
    }
    const printCard = (elem:Data) => {
        return (
            <Grid item xs={2} sm={3} md={3} lg={3} key={elem.id}>
                <Card>
                    <CardActionArea>
                        <FavoriteTwoToneIcon
                            // sx={elem.vip !== '0' ? { position: 'absolute', top: '2%', right: '2%', color: 'orange' } : { position: 'absolute', top: '2%', right: '2%'}}
                            sx={{
                                zIndex: 100,
                                position: 'absolute',
                                padding: '3px',
                                margin: '3px',
                                right: 0,
                                top: 0,
                                color: elem.is_favorite == "1" ? 'orange' : 'white',
                                cursor: 'pointer',
                                backgroundColor: 'rgba(128,128,128,0.5)',
                                fontSize: '32px',
                                borderRadius: '20px'
                            }}
                            onClick={(e) => {
                                var targetEvent = e.currentTarget;
                                addLikedPerson(elem.id, function(data: any){
                                    if(data.status && data.status == true) {
                                        try {
                                            if(data.added == true){
                                                targetEvent.style.color = 'orange';
                                            }else{
                                                targetEvent.style.color = 'white';
                                            }
                                        }catch (e) {}
                                    }
                                });
                            }}
                        />
                        <CardMedia
                            onClick={() => {handleOpen(); setUser(elem)}}
                            component="img"
                            loading="lazy"
                            height="140"
                            image={getImagesSrc(elem)}
                            alt="Фото профиля"
                            data-id={elem.id}
                            data-vip={elem.vip}
                            sx={{ '&': { objectFit: 'cover', backgroundSize: '100% auto' }, minHeight: '28vh'}}
                        />
                        {printCompatible(elem.compatible)}
                        {printRegionLabel(elem.city)}
                        {printLock(elem.vip, elem.lock, elem.hide_btn)}
                        {/*elem.user_OutNum && elem.user_OutNum != '' ? printLeftBottom(elem.user_OutNum, elem.id) : printLeftBottom('id'+elem.id, elem.id)*/}
                        {printLeftBottom('id'+elem.id, elem.id)}
                    </CardActionArea>
                </Card>
            </Grid>
        )
    }

    const printNew = () => {
        let newProfiles:Data[] = [];
        filterationArr?.filter(elem => elem.vip === '0' && elem.isnew &&
            elem.gender[0] !== ((profile?.data && typeof profile?.data !== "string")
                ? profile?.data[0].gender[0] : "Ж"))
            .map(elem => {
                newProfiles.push(elem);
            })
        if(newProfiles.length > 0) {
            /*
            <Typography component='div' style={{
                width: 'calc(100% - 30px)',
                height: '1px',
                border: '1px solid #cfe7ff',
                backgroundColor: '#cfe7ff',
                borderRadius: '3px',
                margin: '15px 20px 5px 15px'
            }}/>
            */
            return (
                <>
                    {profiles.isSuccess && <Alert
                      severity="info"
                      sx={{margin: '15px 20px 20px 15px'}}
                    >Новые профили за <strong>{printCurrentMonth()}</strong></Alert>}
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 9, md: 12, lg: 16}}
                          sx={{justifyContent: 'center'}}>
                        {
                            newProfiles.map(elem => {
                                return printCard(elem)
                            })
                        }
                    </Grid>
                </>
            );
        }
        return null;
    }

    const printButtons = (check: boolean) => {
        const standartTariff = ['0','15','16'];
        try {
            //@ts-ignore
            let tariff = profile.data[0].tariff;
            if(check && tariff == '14' || !check) {
                let code = '';
                let showButtons = false;
                if (tariff == '14') { // FREEZE
                    showButtons = true;
                } else {
                    if (standartTariff.indexOf(tariff) > -1) {
                        //@ts-ignore
                        code = profile.data[0].birthday.split('.')[2];
                        if (code.length == 4) {
                            showButtons = true;
                        }
                    }
                }
                if (showButtons === true) {
                    return <Grid container xs={2} sm={2} md={2} lg={2}
                                 columns={{xs: 2, sm: 2, md: 2, lg: 2}}
                                 sx={{justifyContent: 'center'}}>
                        <DialogPassword numberVariant={2} code={code}/>
                        <DialogPassword numberVariant={1} code={code}/>
                    </Grid>
                }
            }
        }catch (e){}
        return false
    }

    return(
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            {profiles.isSuccess && <Alert
              severity="success"
              sx={{ margin: '15px 20px 20px 15px' }}
            >
                <p style={{margin: '0'}}>Здравствуйте{profile.data && printName()}!</p>
                {profile.data && printId()}
                <p style={{margin: '0'}}>На&nbsp;текущий момент с&nbsp;вами совместимо <strong>{countPartners}</strong> партнер{printWord()}.{printTariffInfo()}</p>
            </Alert>}
            { profiles.isSuccess && profile.data && printButtons(true) }
            { profiles.isSuccess && profile.data && printActiveEmailWarning() }
            <Box style={{padding: '15px', flex: '1'}}>
                {/* {(profile.data && typeof profile.data !== 'string') ?
                    <Favorites fav={profile.data[0] && profile.data[0].fav} /> : null}
                <hr /> */}
                {profiles.isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
                {user ?
                    <Dialog
                        open={open}
                        onClose={ handleClose }
                        scroll={'body'}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogContent  >
                            <User userData={user} />
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Cancel</Button> */}
                            <Button onClick={handleClose}>Закрыть</Button>
                        </DialogActions>
                    </Dialog>
                    : null }
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 9, md: 12, lg: 16 }}
                    sx={{justifyContent: 'center'}}>
                    {profile.data &&
                        filterationArr?.filter(elem => elem.vip === '1' &&
                            elem.gender[0] !== ((profile?.data && typeof profile?.data !== "string")
                                ? profile?.data[0].gender[0] : "Ж") )
                        .map(elem => {
                            /*if(elem.id == '6744'){
                                console.log('user test', elem);
                            }*/
                            return printCard(elem)
                        })
                    }
                    {/*
                    .sort((a, b) => {
                            //console.log('a.birthday',a.birthday);
                            if(a.birthday && b.birthday) {
                                return (new Date(
                                    a.birthday.toString()
                                    //.split('-').reverse().join('-')
                                ).getTime() >= new Date(
                                    b.birthday.toString()
                                    //.split('-').reverse().join('-')
                                ).getTime()) ? -1 : 1;
                            }
                            return -1;
                         })
                    */}
                </Grid>
                { printNew() }
                <Typography component='div' style={{
                    width: 'calc(100% - 30px)',
                    height: '1px',
                    border: '1px solid #cfe7ff',
                    backgroundColor: '#cfe7ff',
                    borderRadius: '3px',
                    margin: '15px 20px 5px 15px'
                }}/>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 9, md: 12, lg: 16 }}
                    sx={{ justifyContent: 'center' }}>
                    {filterationArr?.filter(elem => elem.vip === '0' && !elem.isnew &&
                        elem.gender[0] !== ((profile?.data && typeof profile?.data !== "string")
                            ? profile?.data[0].gender[0] : "Ж"))
                        .map(elem => {
                            return printCard(elem)
                        })
                    }
                </Grid>
                <Outlet />
            </Box>
            { profiles.isSuccess && profile.data && printButtons(false) }
            { PrintFooterAstrodating() }
        </Box>
    )
}

export default Cabinet
