import Box from '@mui/material/Box';
import { blue } from '@mui/material/colors';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
// import SvgIcon from '@mui/material/SvgIcon';
// import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FC } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { changePath, p } from '../../../reducers/adminSlice';
import { useAppDispatch } from '../../../hooks/hooks';
import { Button, Grid, createSvgIcon } from '@mui/material';
import { deleteCookie, getCookie } from '../../../functions/Cookie';
import { auth, MainState } from '../../../reducers/mainSlice';

import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SecurityIcon from '@mui/icons-material/Security';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import StorageIcon from '@mui/icons-material/Storage';
import StarsIcon from '@mui/icons-material/Stars';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import InventoryIcon from '@mui/icons-material/Inventory';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import BackupIcon from '@mui/icons-material/Backup';
import ConstructionIcon from '@mui/icons-material/Construction';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import Search from "./search";

const VkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 20 20">
            <path fill="#0077FF" className="st0" d="M13.162 18.994c.609 0 .858-.406.851-.915-.031-1.917.714-2.949 2.059-1.604 1.488 1.488 1.796 2.519 3.603 2.519h3.2c.808 0 1.126-.26 1.126-.668 0-.863-1.421-2.386-2.625-3.504-1.686-1.565-1.765-1.602-.313-3.486 1.801-2.339 4.157-5.336 2.073-5.336h-3.981c-.772 0-.828.435-1.103 1.083-.995 2.347-2.886 5.387-3.604 4.922-.751-.485-.407-2.406-.35-5.261.015-.754.011-1.271-1.141-1.539-.629-.145-1.241-.205-1.809-.205-2.273 0-3.841.953-2.95 1.119 1.571.293 1.42 3.692 1.054 5.16-.638 2.556-3.036-2.024-4.035-4.305-.241-.548-.315-.974-1.175-.974h-3.255c-.492 0-.787.16-.787.516 0 .602 2.96 6.72 5.786 9.77 2.756 2.975 5.48 2.708 7.376 2.708z"/>
        </svg>
    );
};
interface SidebarCells {
    name: string,
    href: string,
    icon: string,
    color: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    dist?: string,
}
interface IconProps {
    icon: string;
    className: string;
}

const getIcon = (
    iconName: string,
    iconColor: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
) => {
    switch (iconName){
        case 'moderation':          return (<SecurityIcon color={iconColor} />); break;
        case 'moderation_admin':    return (<AdminPanelSettingsIcon color={iconColor} />); break;
        case 'line1':               return (<LooksOneIcon color={iconColor} />); break;
        case 'line2':               return (<LooksTwoIcon color={iconColor} />); break;
        case 'line3':               return (<Looks3Icon color={iconColor} />); break;
        case 'line4':               return (<Looks4Icon color={iconColor} />); break;
        case 'line5':               return (<Looks5Icon color={iconColor} />); break;
        case 'in_process':          return (<EngineeringIcon color={iconColor} />); break;
        case 'clients_list':        return (<FolderSharedIcon color={iconColor} />); break;
        case 'clients_add':         return (<PersonAddAlt1Icon color={iconColor} />); break;
        case 'search_active':       return (<TwoWheelerIcon color={iconColor} />); break;
        case 'search_passive':      return (<AgricultureIcon color={iconColor} />); break;
        case 'search_all':          return (<StorageIcon color={iconColor} />); break;
        //case 'favorites':           return (<StarsIcon color={iconColor} />); break;
        case 'text_cons':           return (<FormatColorTextIcon color={iconColor} />); break;
        case 'archive':             return (<InventoryIcon color={iconColor} />); break;
        case 'trash':               return (<DeleteSweepIcon color={iconColor} />); break;
        case 'clients_vk':          return (<VkIcon />); break;
        //case 'clients_excel':       return (<PlaylistAddCheckCircleIcon color={iconColor} />); break;
        //case 'clients_import':      return (<BackupIcon />); break;
        case 'mail_icon':           return (<MailIcon color={iconColor} />); break;
        case 'tickets':             return (<ConstructionIcon color={iconColor} />); break;
        case 'emojiemotions':       return (<EmojiEmotionsIcon color={iconColor} />); break;
        case 'action_log':          return (<PrivacyTipIcon color={iconColor} />); break;
        case 'inbox_icon':
        default:                    return '<InboxIcon />';break;
    }
};

const getColor = (
    tmpColor: string,
) => {
    let isColor: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' = 'disabled';
    switch (tmpColor){
        case 'inherit':   isColor = 'inherit';   break;
        case 'action':    isColor = 'action';    break;
        case 'primary':   isColor = 'primary';   break;
        case 'secondary': isColor = 'secondary'; break;
        case 'error':     isColor = 'error';     break;
        case 'info':      isColor = 'info';      break;
        case 'success':   isColor = 'success';   break;
        case 'warning':   isColor = 'warning';   break;
    }
    return isColor;
};

interface menuItem{
    name: string,
    status: boolean
}

var activeMenu:string = 'moderation';
const ClippedDrawer: FC = () => {

    const dispatch = useAppDispatch()
    let navigate = useNavigate();

    const Logout = () => {
        deleteCookie('role');
        deleteCookie('token');
        deleteCookie('tariff');
        deleteCookie('coins');
        deleteCookie('gender');
        deleteCookie('update_time');
        deleteCookie('use_coins');
        deleteCookie('menu');
        dispatch(auth({} as MainState))
        navigate('/', { replace: true });
    }

    var menuStatus:menuItem[] = [
        {name: "moderation", status: true},
        {name: "moderation_admin", status: false},
        {name: "line1", status: false},
        {name: "line2", status: false},
        {name: "line3", status: false},
        {name: "line4", status: false},
        {name: "line5", status: false},
        {name: "in_process", status: false},
        {name: "search_active", status: false},
        {name: "search_passive", status: false},
        {name: "search_all", status: false},
        {name: "clients_add", status: false},
        {name: "clients_list", status: false},
        {name: "emojiemotions", status: false},
        {name: "text_cons", status: false},
        {name: "archive", status: false},
        {name: "trash", status: false},
        {name: "clients_vk", status: false},
        {name: "tickets", status: false},
        {name: "action_log", status: false},
    ];
    const toggleStatus = (menuId:string) => {
        if(activeMenu != menuId){
            activeMenu = menuId;
        }
        for (var i = 0; i < menuStatus.length; i++) {
            if (menuStatus[i].name == activeMenu) {
                menuStatus[i].status = true;
            }else{
                menuStatus[i].status = false;
            }
        }
    }
    const getActiveStatus = (menuId:string) => {
        let statusResult = false;
        for (var i = 0; i < menuStatus.length; i++) {
            if (activeMenu == menuId) {
                statusResult = true;
                break;
            }
        }
        return statusResult;
    }
    const menuList = {
        moderation: {
            name: 'Модерация',
            href: 'moderation',
            icon: 'moderation',
            color: getColor('error'),
            dist: 'moderation'
        },
        moderation_admin: {
            name: 'Модерация админа',
            href: 'moderation_admin',
            icon: 'moderation_admin',
            color: getColor('error'),
            dist: 'moderation_admin'
        },
        clients_vk: {
            name: 'Лиды VK',
            href: 'clients_vk',
            icon: 'clients_vk',
            color: getColor('primary'),
            dist: 'clients_vk'
        },
        line1: {
            name: 'Линия 1',
            href: 'lineone',
            icon: 'line1',
            color: getColor('primary'),
            dist: 'moderation'
        },
        line2: {
            name: 'Линия 2',
            href: 'linetwo',
            icon: 'line2',
            color: getColor('primary'),
            dist: 'lines' // lines
        },
        line3: {
            name: 'Линия 3',
            href: 'linethree',
            icon: 'line3',
            color: getColor('primary'),
            dist: 'line3'
        },
        line4: {
            name: 'Линия 4',
            href: 'linefour',
            icon: 'line4',
            color: getColor('primary'),
            dist: 'line4'
        },
        line5: {
            name: 'Линия 5',
            href: 'linefive',
            icon: 'line5',
            color: getColor('primary'),
            dist: 'line5'
        },
        in_process: {
            name: 'На обработке',
            href: 'in_process',
            icon: 'in_process',
            color: getColor('primary'),
            dist: 'cons'
        },
        search_active: {
            name: 'Активный поиск',
            href: 'search_active',
            icon: 'search_active',
            color: getColor('primary'),
            dist: 'profiles'
        },
        search_passive: {
            name: 'Пассивный поиск',
            href: 'search_passive',
            icon: 'search_passive',
            color: getColor('primary'),
            dist: 'profiles'
        },
        search_all: {
            name: 'Общая база',
            href: 'search_all',
            icon: 'search_all',
            color: getColor('disabled'),
            dist: 'profiles'
        },
        clients_add: {
            name: 'Добавить клиента',
            href: 'profile-add',
            icon: 'clients_add',
            color: getColor('primary'),
            dist: 'profile-add'
        },
        clients_list: {
            name: 'Клиенты',
            href: 'clients_list',
            icon: 'clients_list',
            color: getColor('warning'),
            dist: 'clients_list'
        },
        minion: { // sort
            name: 'Я клиент',
            href: '/admin/profile/513',
            icon: 'emojiemotions',
            color: getColor('warning'),
        },
        text_cons: {
            name: 'Настройки',
            href: 'config',
            icon: 'text_cons',
            color: getColor('secondary'),
            dist: 'config'
        },
        archive: {
            name: 'Архив',
            href: 'archive',
            icon: 'archive',
            color: getColor('disabled'),
            dist: 'archive'
        },
        trash: {
            name: 'Корзина',
            href: 'korzina',
            icon: 'trash',
            color: getColor('disabled'),
            dist: 'korzina'
        },
        tickets: {
            name: 'Написать разработчику',
            href: 'tickets',
            icon: 'tickets',
            color: getColor('error'),
            dist: 'tickets'
        },

        action_log: {
            name: 'Логи',
            icon: 'action_log',
            color: getColor('primary'),
            href: 'action_log',
            dist: 'action_log'
        },
    }
    const returnSidebarCells = () => {
        let result: SidebarCells[];
        switch (getCookie('role')) {
            case 'ok_admin':
                result = ([
                    menuList.moderation,         // Модерация
                    menuList.clients_vk,         // Пользователи VK группы
                    menuList.line1,              // Линия 1
                    menuList.line2,              // Линия 2
                    menuList.in_process,         // На обработке
                    menuList.minion,             // Я клиент
                    menuList.action_log,         // Лог действий
                    menuList.clients_list,       // Клиенты
                    menuList.clients_add,        // Добавить клиента
                    menuList.search_active,      // Активный поиск
                    menuList.search_passive,     // Пассивный поиск
                    menuList.search_all,         // Общая база
                    menuList.text_cons,          // Текстовая конс
                    menuList.archive,            // Архив
                    menuList.trash,              // Корзина
                    menuList.line3,              // Линия 3
                    menuList.line4,              // Линия 4
                    menuList.line5,              // Линия 5
                    menuList.tickets,            // Предложить доработку
                ]);
                break;
            case 'consideration': // Руководитель отдела обслуживания, ассистент директора
                result = ([
                    menuList.in_process,         // На обработке
                    menuList.clients_vk,         // Пользователи VK группы
                    menuList.moderation_admin,   // Модерация админа
                    menuList.line1,              // Линия 1
                    menuList.line2,              // Линия 2
                    menuList.line3,              // Линия 3
                    menuList.line4,              // Линия 4
                    menuList.clients_add,        // Добавить клиента
                    menuList.search_active,      // Активный поиск
                    menuList.search_passive,     // Пассивный поиск
                    menuList.search_all,         // Общая база
                    menuList.tickets,            // Предложить доработку
                ]);
                break;
            case 'ok_mainModer':
                result = ([
                    menuList.moderation,         // Модерация
                    menuList.clients_vk,         // Пользователи VK группы
                    menuList.line2,              // Линия 2
                    menuList.in_process,         // На обработке
                    menuList.clients_add,        // Добавить клиента
                    menuList.archive,            // Архив
                    menuList.trash,              // Корзина
                    menuList.tickets,            // Предложить доработку
                ]);
                break;
            case 'ok_mainDok':
                result = ([
                    menuList.moderation,         // Модерация
                    menuList.clients_vk,         // Пользователи VK группы
                    menuList.line3,              // Линия 3
                    menuList.line4,              // Линия 4
                    menuList.line5,              // Линия 5
                    menuList.in_process,         // На обработке
                    menuList.clients_add,        // Добавить клиента
                    menuList.search_passive,     // Пассивный поиск
                    menuList.archive,            // Архив
                    menuList.trash,              // Корзина
                    menuList.tickets,            // Предложить доработку
                ]);
                break;
            default:
                result = ([
                    menuList.moderation,         // Модерация
                    menuList.clients_vk,         // Пользователи VK группы
                    menuList.clients_add,        // Добавить клиента
                    menuList.tickets,            // Предложить доработку
                ]);
            break;
        }
        return result;
    }

    const sidebarCells: SidebarCells[] = returnSidebarCells()

    const setDefaultMenu = () => {
        let target = location.pathname;
        sidebarCells.forEach((el)=>{
            if(target.indexOf('/admin/'+el.href) !== -1){
                activeMenu = el.icon;
            }
        })
    }
    setDefaultMenu();

    return (
        <Box sx={{ display: 'flex', maxWidth: '100%' }} >
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        AstroDating
                    </Typography>
                    {<Search />}
                    <Button 
                        sx={{margin: '0 0 0 auto', backgroundColor: 'red'}}
                        variant='contained'
                        onClick={Logout}>
                        Выйти
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: '170px',
                    maxWidth: '25%',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: '170px', maxWidth: '25%', boxSizing: 'border-box' },
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
                        {sidebarCells.map((text, index) => {
                            return(
                                <Link
                                    to={text.href}
                                    key={index}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                    onClick={(e) => toggleStatus(text.icon)}
                                >
                                    <ListItem
                                        onClick={() => text.dist && dispatch(changePath({ path: text.dist, name: text.name}))}
                                        sx={{
                                            maxHeight: 'inherit',
                                            borderBottom: '1mm solid rgb(170, 50, 220, .6);',
                                            bgcolor: (getActiveStatus(text.icon) ? blue[100] : null)
                                    }}>

                                        <ListItemText primary={
                                            <>
                                                <Typography
                                                    component='p'
                                                    style={{
                                                        textAlign: 'center',
                                                        margin: 0,
                                                        position: 'relative',
                                                        marginLeft: '25px'
                                                }}>
                                                    <ListItemIcon sx={{
                                                        position: 'absolute',
                                                        left: '-25px',
                                                    }}>
                                                        {/*<MailIcon width={'28px'} />*/}
                                                        {getIcon(text.icon, text.color)}
                                                    </ListItemIcon>
                                                    {text.name}
                                                </Typography>

                                            </>}
                                            sx={{ maxHeight: 'inherit'}}
                                        />

                                    </ListItem>
                                </Link>
                            )
                        })}
                    <Divider />
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: 'calc(100% - 170px)' }}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
}

export default ClippedDrawer