import React, { FC } from "react";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuthAccountMutation } from '../services/goroskop';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { LoginType } from '../types/login';
import { useSnackbar } from 'notistack';
import Email from './quiz/Email';
import Pass from './quiz/Pass';
import { deleteAllCookie, getCookie, setCookie } from '../functions/Cookie'; // TODO: kndev Передалать на токен авторизации
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import Link from '@mui/material/Link';
import AgreementPopUp from "./quiz/agreement";
import Copyright from "./quiz/copyright";
import Socialicons from "./quiz/socialicons";
import {Data} from "../types/data";
import { auth, MainState } from '../reducers/mainSlice';

const theme = createTheme();

export default function Login() {
    let { token } = useParams()

    const [submitData, { error, data, isError, isSuccess }] = useAuthAccountMutation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { register, handleSubmit, control } = useForm<LoginType>();
    const dispatch = useAppDispatch();

    let navigate = useNavigate();
    const onSubmit: SubmitHandler<LoginType> = subData => {
        deleteAllCookie();
        const fd = new FormData();
        fd.append('email', subData.email);
        fd.append('pass', subData.password);
        submitData({ name: 'login.php', data: fd }).then(response => {
            // @ts-ignore
            let res = response.data;
            if (res && typeof(res.status) === 'boolean') {
                if(res.msg && res.msg.length > 0) {
                    enqueueSnackbar(res.msg, {variant: (res.status === true ? 'success' : 'error')});
                } else {
                    enqueueSnackbar('Ошибка передачи данных', {variant: 'error'});
                }
                if(res.status){
                    authMe(res);
                }
            }
        });
    };

    const daysCookie = 365;

    const authMe = (data: {
        Account: Data,
        whoIs: string,
        token: string,
        status: boolean,
        msg: string,
        type: string,
        tariff: string,
        gender: number,
        color: number,
        use_coins: boolean
    }) => {
        if(data.status === true) {
            const whoIs = data.whoIs;

            if (whoIs !== '[]' && data.token) {
                let authData: MainState = {
                    Account: data.Account,
                    role: data.whoIs,
                    easyRole: "unlogined",
                    token: data.token,
                    color: data.color,
                    use_coins: data.use_coins
                };

                setCookie('menu', (data.color).toString(), daysCookie);

                if(data.gender) {
                    setCookie('gender', (data.gender).toString(), daysCookie);
                }
                if(data.tariff) {
                    setCookie('tariff', (data.tariff).toString(), daysCookie);
                }
                setCookie('uid', data.Account.id, daysCookie);
                setCookie('role', whoIs, daysCookie);
                setCookie('token', authData.token, daysCookie);
                setCookie('update_time', String(new Date().getTime()), daysCookie);

                if(typeof(data.use_coins) === 'boolean') {
                    if(data.use_coins) {
                        setCookie('use_coins', '1', daysCookie);
                    } else {
                        setCookie('use_coins', '0', daysCookie);
                    }
                }

                //@ts-ignore
                if(typeof(data.Account.tariff_coins) !== 'undefined'){
                    //@ts-ignore
                    setCookie('coins', data.Account.tariff_coins, daysCookie);
                }

                if(typeof(data.Account.firstname) !== "undefined"){
                    localStorage.setItem('firstname', data.Account.firstname);
                }
                if(typeof(data.Account.images) !== "undefined"){
                    let userImages = (data.Account.images).split(',');
                    if(userImages[0]) {
                        localStorage.setItem('avatar', userImages[0]);
                    }
                }
                if(typeof(data.Account.vkpage) !== "undefined" && data.Account.vkpage === '1'){
                    localStorage.setItem('vkpage', data.Account.vkpage);
                } else {
                    localStorage.removeItem('vkpage');
                }

                switch (whoIs){
                    case 'ok_user':
                    case 'user':
                        authData.easyRole = "user";
                        navigate(`../cabinet`, {replace: true});
                        break;
                    default:
                        authData.easyRole = "admin";
                        navigate('../admin/moderation', {replace: true});
                        break;
                }
            } else {
                enqueueSnackbar('Неправильный логин или пароль', {
                    variant: 'error',
                });
            }
        } else {
            if(data.msg) {
                if(data.msg == 'moderator'){
                    enqueueSnackbar('Учетная запись не активна, обратитесь пожалуйста к администрации для ускоренного подтверждения ваших данных.', {
                        variant: 'info'
                    });
                } else {
                    enqueueSnackbar(data.msg, {
                        variant: data.status ? 'success' : 'error'
                    });
                }
            }
        }
    };

    if(token) {
        let storageToken = localStorage.getItem('token');
        if (!storageToken || token != storageToken) {
            localStorage.setItem('token', token);
            localStorage.setItem('token_status', '0');
        }
    }

    const checkToken = () => {
        let storageTokenStatus = localStorage.getItem('token_status');
        if(!storageTokenStatus || storageTokenStatus == '0') {
            let storageToken = localStorage.getItem('token');
            if (storageToken) {
                localStorage.setItem('token_status', '1');
                deleteAllCookie();
                const fd = new FormData();
                fd.append('token', storageToken);
                submitData({name: 'confirm.php', data: fd}).then((e) => {
                    //@ts-ignore
                    authMe(e.data);
                    localStorage.setItem('token_status', '1');
                });
            }
        }
    };

    checkToken();

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h2" variant="h6">
                        Бюро счастливых знакомств
                    </Typography>
                    <img style={{
                        margin: '5px auto 10px auto',
                        width: '64px',
                        height: '64px'  }} src='/images/logo.png' />
                    <Typography component="h1" style={{fontSize: '18px'}}>
                        Авторизация
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                        <Email control={control} rules={{ autoComplete: 'email', autoFocus: true}} />
                        <Pass control={control} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Войти
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to={{ pathname: "/"}} component={RouterLink} variant="body2" >На главную</Link>
                            </Grid>
                            {<Grid item xs sx={{textAlign: 'right'}}>
                                <Link to={{ pathname: "/recovery"}} component={RouterLink} variant="body2" >
                                    Забыли пароль?
                                </Link>
                            </Grid>}
                        </Grid>
                        <Grid container>
                            <Grid item xs sx={{textAlign: 'center', mt: 2}}>
                                <Link href="https://vk.me/svastro" rel={'nofollow'} target={'_blank'} variant="body2">
                                    Активировать учетную запись
                                </Link>
                            </Grid>
                        </Grid>
                        <AgreementPopUp welcomeText='Продолжая пользоваться сайтом, вы соглашаетесь на'/>
                    </Box>
                </Box>
                {Socialicons(false)}
                <Copyright />
            </Container>

        </ThemeProvider>
    );
}
