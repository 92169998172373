import React from 'react';
import { Control, Controller, FieldValues, Path, PathValue, UnpackNestedValue } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormControl } from '@mui/material';

interface FormInputCheckboxProps<T extends FieldValues> {
    name: Path<T>;
    control: Control<T>;
    label?: string;
    defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>;
    className?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInputCheckboxLabel = <T extends FieldValues>({
    name,
    control,
    label,
    defaultValue = "0" as UnpackNestedValue<PathValue<T, Path<T>>>,
    className = '',
    onChange,
}: FormInputCheckboxProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange: fieldOnChange, value, ref } }) => (
                <FormControl className={className}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value === "1"}
                                onChange={(e) => {
                                    fieldOnChange(e.target.checked ? "1" : "0"); // Реальное обновление формы
                                    onChange?.(e); // Вызываем переданный onChange, если он есть
                                }}
                                inputRef={ref}
                                color="primary"
                            />
                        }
                        label={label || ''}
                    />
                </FormControl>
            )}
        />
    );
};

export default FormInputCheckboxLabel;